import Fade from "@material-ui/core/Fade";
// @material-ui/icons
import Modal from "@material-ui/core/Modal";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/contactUsStyle.js";
import ContactForm from "../sections/ContactForm";
import Button from "@material-ui/core/Button";
// core components
import GridContainer from "../sections/GridContainer";
import GridItem from "../sections/GridItem.js";
import React, { useState } from "react";

const useStyles = makeStyles(styles);
const useModalStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#fff",
    width: 500,
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
  },
  button: {
    background: "cyan",
    color: "black",
  }
}));

export default function ContactUs() {
  const classes = useStyles();
  const modalStyles = useModalStyles();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className={classes.section} id="contact">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={modalStyles.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <div className={modalStyles.paper}>
            <ContactForm />
          </div>
        </Fade>
      </Modal>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>
            <Button
            className={modalStyles.button}
              size="large"
              onClick={() => setOpen(true)}
            >
              Get In Touch With Us
            </Button>{" "}
          </h2>
        </GridItem>
      </GridContainer>
    </div>
  );
}
