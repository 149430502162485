import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import "./TechnologyTiles.css"

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const TechnologyTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const sectionHeader = {
    title: "Technologies",
    paragraph: "",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container" id="technologies">
        <div className={innerClasses} style={{padding:"0"}}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className="container-flex">
            <div className="img-container">
              <img src={require("../../assets/images/aws_logo.png")} alt="logo" />
            </div>
            <div className="img-container">
              <img src={require("../../assets/images/dot_net_logo.png")} alt="logo" />
            </div>
            <div className="img-container">
              <img src={require("../../assets/images/android_logo.jpg")} alt="logo" />
            </div>
            <div className="img-container">
              <img src={require("../../assets/images/azure_logo.png")} alt="logo" />
            </div>
            <div className="img-container">
              <img src={require("../../assets/images/flutter_logo.png")} alt="logo" />
            </div>
            <div className="img-container">
              <img src={require("../../assets/images/java_logo.png")} alt="logo" />
            </div>
            <div className="img-container">
              <img src={require("../../assets/images/mongo_db_logo.png")} alt="logo" />
            </div>
            <div className="img-container">
              <img src={require("../../assets/images/postgres_sql_logo.png")} alt="logo" />
            </div>
            <div className="img-container">
              <img src={require("../../assets/images/react_logo.png")} alt="logo" />
            </div>
            <div className="img-container">
              <img src={require("../../assets/images/sql_server_logo.png")} alt="logo" />
            </div>
            <div className="img-container">
              <img src={require("../../assets/images/hyperledger_logo.png")} alt="logo" />
            </div>
            <div className="img-container">
              <img src={require("../../assets/images/ibm_cloud_logo.png")} alt="logo" />
            </div>
            <div className="img-container">
              <img src={require("../../assets/images/node_logo.png")} alt="logo" />
            </div>
            <div className="img-container">
              <img src={require("../../assets/images/Power_Bi_logo.png")} alt="logo" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

TechnologyTiles.propTypes = propTypes;
TechnologyTiles.defaultProps = defaultProps;

export default TechnologyTiles;
